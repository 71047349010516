<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem>
        <span>结算单号：</span>
        <i-input v-model="queryFrom.settlement_code" placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>
      </FormItem>
      <FormItem>
        <span>客户名称：</span>
        <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex" clearable>
          <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
<!--      <FormItem>-->
<!--        <span>结算单时间：</span>-->
<!--        <DatePicker :editable='false'  type="daterange" class="iviewIptWidth250" placeholder="请选择" @on-change="changeTime"></DatePicker>-->
<!--      </FormItem>-->
      <FormItem>
        <span class="label">结算单时间：</span>
        <DatePicker class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker>
        <span class="choseTimeZhi">至</span>
        <DatePicker :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录">
      <div slot="summary" class="summary" v-if="listData.length">
        <div>
          <span style="margin-right: 20px;"
          >总数量：<span class="color389">{{ total_number }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ total_amount }}</span
          >
        </div>
      </div>
    </Table>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
  import Table from '@/components/table'
  import TipsComponent from '@/components/tipsComponent'
  import { mapState } from 'vuex'
  export default {
    name: 'entrustSettlementConsign',
    components: {
      Table,
      TipsComponent,
    },
    computed: {
      ...mapState(['clientNameList']),
    },
    data() {
      return {
        options: {},
        deleteStatus: false,
        supplierIndex: -1,
        total_number: 0,
        total_amount: 0,
        isLoad: true,
        listColumns: [
          {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
          {
            title: '结算单号',
            align: 'center',
            render: (h, param) => {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row)
                      },
                    },
                  },
                  param.row.settlement_code
                ),
              ])
            },
          },
          {
            title: '客户名称',
            key: 'customer_name',
            align: 'center',
          },
          {
            title: '产品',
            key: 'product_name_str',
            align: 'center',
          },
          {
            title: '数量',
            key: 'quantity',
            align: 'center',
            width: 90,
          },
          {
            title: '金额',
            align: 'center',
            width: 160,
            render: (h, param) => {
              return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '0')])
            },
          },
          {
            title: '结算单时间',
            key: 'settlementTime',
            align: 'center',
            width: 240,
          },

          {
            title: '操作',
            align: 'center',
            width: 150,
            render: (h, param) => {
              return h('div', [
                // h(
                //   'span',
                //   {
                //     style: {
                //       color: '#389AFC',
                //
                //       cursor: 'pointer',
                //     },
                //     on: {
                //       click: () => {
                //         this.goDetails(param.row)
                //       },
                //     },
                //   },
                //   '详情'
                // ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.deleteOrder(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            },
          },
        ],
        listData: [
          {
            settlement_code: '1214533'
          }
        ],
        total: 0,
        queryFrom: {
          page: 1,
          rows: 10,
          settlement_code: '',
          customer_id: '',
          customer_type: '',
          settlement_time_begin: '',
          settlement_time_end: '',
        },
        deleteCode: '',
      }
    },
    mounted() {
      this.query()
    },
    methods: {
      query() {
        this.queryFrom.page = 1
        this.queryList()
      },
      // 查询列表.
      queryList() {
        this.isLoad = true
        if (this.supplierIndex >= 0) {
          this.queryFrom.customer_id = this.clientNameList[this.supplierIndex].customer_id
          this.queryFrom.customer_type = this.clientNameList[this.supplierIndex].customer_type
        } else {
          this.queryFrom.customer_id = ''
          this.queryFrom.customer_type = ''
        }
        this.$http.get(this.$apiConsign.purchaseCommissioned, this.queryFrom, true).then(res => {
          this.isLoad = false
          this.total = res.total
          this.total_amount = res.total_amount
          this.total_number = res.total_number
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].settlementTime = this.$moment(res.data[i].settlement_time * 1000).format('YYYY-MM-DD')
          }
          this.listData = res.data
        })
      },
      // 时间改变
      changeTime(e, num) {
        if (num == 1) {
          let that = this
          that.queryFrom.settlement_time_begin = e
          that.options = {
            disabledDate(date) {
              return date && date.valueOf() < new Date(that.queryFrom.settlement_time_begin) - 86400000
            },
          }
        } else {
          this.queryFrom.settlement_time_end = e
        }
      },
      // 编辑
      goDetails(row) {
        this.$router.push({
          path: '/entrustSettlementDetailsConsign',
          query: {
            id: row.id
          }
        })
      },
      // 删除
      deleteOrder(row) {
        this.deleteCode = row.id
        this.deleteStatus = true
      },
      // 确认删除
      sureDelete() {
        this.$http.DeleteAll(this.$apiConsign.purchaseCommissioned, { id: this.deleteCode }, true).then(res => {
          this.query()
          this.deleteStatus = false
        })
      },
      changePage(e) {
        this.queryFrom.page = e
        this.queryList()
      },
    },
  }
</script>

<style scoped lang="less">
  .marginRight60 {
    margin-right: 60px;
  }
</style>
